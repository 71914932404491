<template>
  <div class="company-list">
    <div class="input-container">
      <a-input-search
        class="input-bar"
        placeholder="请输入项目名称或关键词进行查询"
        @search="fetchData"
      />
    </div>
    <a-table
      :columns="table.columns"
      :data-source="params.productList"
      :loading="table.loading"
      rowKey="id"
      :pagination="{ pageSize: 10 }"
    />
  </div>
</template>

<script>
import { getSearchProductHistory } from "@/api/user";
import { getProductList } from "@/api/product";

export default {
  data() {
    return {
      params: {
        productList: [],
      },
      table: {
        loading: false,
        columns: [
          {
            title: "项目名称",
            dataIndex: "name",
            width: "150px",
            customRender: (text, row) => {
              return (
                <router-link to={`/product/${row.id}/info`}>{text}</router-link>
              );
            },
          },
          {
            title: "项目来源",
            dataIndex: "originType",
            width: "100px",
            customRender: (text) => {
              switch (text) {
                case "SCP":
                  return "分享起源";
                case "QMP":
                  return "企名片";
                default:
                  return "";
              }
            },
          },
          {
            title: "最新融资轮次",
            dataIndex: "round",
            width: "100px",
          },
        ],
      },
    };
  },
  methods: {
    async fetchData(keyword) {
      this.table.loading = true;
      getProductList({
        keyword,
      })
        .then((res) => {
          this.params.productList = res.data;
        })
        .finally(() => {
          setTimeout(() => {
            this.table.loading = false;
          }, 500);
        });
    },
    validata(keyword) {
      if (!keyword) {
        this.$message.error("请输入关键词进行查询！");
        return false;
      }
      return true;
    },
  },
  async created() {
    this.params.productList = (await getSearchProductHistory()).data;
  },
};
</script>

<style lang="less" scoped>
.company-list {
  background-color: white;
  padding: 20px;
  .input-container {
    // float: right;
    text-align: right;
    .input-bar {
      width: 350px;
      margin-bottom: 10px;
    }
  }
}
</style>
