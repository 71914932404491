import { render, staticRenderFns } from "./ProductList.vue?vue&type=template&id=40d29fb5&scoped=true&"
import script from "./ProductList.vue?vue&type=script&lang=js&"
export * from "./ProductList.vue?vue&type=script&lang=js&"
import style0 from "./ProductList.vue?vue&type=style&index=0&id=40d29fb5&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d29fb5",
  null
  
)

export default component.exports